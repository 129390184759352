export const Application = Object.freeze({
  APPLICATION_NAME: 'TopSky - Flow Manager',
});

export const Environment = Object.freeze({
  DEV: 'dev',
  BETA: 'beta',
});

export const SourceNames = Object.freeze({
  NMOPS: 'NMOPS',
  NMPREOPS: 'NMPREOPS',
});

export const AVDStatusTypes = Object.freeze({
  UP: 'UP',
  DEGRADED: 'DEGRADED',
  DOWN: 'DOWN',
});

export const ApplicationRoles = Object.freeze({
  APP_SEQ: 'seq_access',
});

export const Routes = Object.freeze({
  ROOT: '/',
  LOGIN: '/login',
  OAUTH: '/oauth',
});

export const currentAddress = `${window.location.protocol}//${window.location.host}`;
const redirectToken = window.env.AUTH_REDIRECT_URI_TOKEN;
export const Authentication = Object.freeze({
  LOGIN_REDIRECT: window.env.OIDC_LOGIN_REDIRECT.replace(
    redirectToken,
    currentAddress,
  ),
});

export const worldAreaPolygon =
  '-180%2090%2C%20-180%20-90%2C%20180%20-90%2C%20180%2090%2C%20-180%2090';

export const CriticalServices = Object.freeze([
  'capa-plans',
  'flights',
  'positions',
  'trajectories',
  'entry',
  'hotspots',
  'timeline',
  'occ',
  'config',
  'secto-plans',
  'reflocation',
  'time',
  'def-areas',
  'userinfo',
  'whatifs',
  'charts',
  'flow',
  'forecast',
]);

export const avoidReplayPaths = Object.freeze([
  '/api/0/secto-conf-layout',
]);

export const Severities = Object.freeze({
  SEVERE: 'SEVERE',
  MODERATE: 'MODERATE',
  LIGHT: 'LIGHT',
});

export const WeatherSeverities = Object.freeze({
  SEVERE: 'SEV',
  MODERATE: 'MODERATE',
  LIGHT: 'LIGHT',
});

export const TimeTypes = Object.freeze({
  RUNNING: 'RUNNING',
  FIXED: 'FIXED',
});

export const HotspotColor = Object.freeze({
  INACTIVE: '#808080',
  LIGHT: '#bee9c5',
  MODERATE: '#f8e71c',
  MODERATE_IN_PAST: 'rgba(248, 231, 28, .5)',
  SEVERE: '#ff3455',
  SEVERE_IN_PAST: 'rgba(255, 52, 85, .5)',
});

export const WeatherLayersColor = Object.freeze({
  LIGHT: '#0d8274', // GreenDarker
  MODERATE: '#edbd00', // YellowDarker
  SEVERE: '#c3253f', // RedDarker
});

export const HotspotFontColor = Object.freeze({
  LIGHT: 'black',
  MODERATE: 'black',
  SEVERE: 'white',
});

export const TrafficLoadType = Object.freeze({
  OCC: 'OCC',
  ENTRY: 'ENTRY',
});

export const TrafficPopupStatus = Object.freeze({
  OPEN: 'Open',
  COLLAPSED: 'Collapsed',
});

export const TrafficLoadColors = Object.freeze({
  NORMAL_LINES: 'black',
  NORMAL_BARS: 'grey',
  MODERATE: '#ff7105',
  SEVERE: '#ff1744',
  COMPLEXITY: '#00bfa5',
});

export const TrafficLoadChartLayer = Object.freeze({
  BACKGROUND: 'Back',
  FOREGROUND: 'Front',
});

export const TimelineColors = Object.freeze({
  SELECTED_FLIGHT_CROSS: '#4283ff',
});

export const Time = Object.freeze({
  MINUTE_IN_MILISECONDS: 60000,
  HOUR_IN_MILISECONDS: 3600000,
});

export const PlanningConfigs = Object.freeze({
  STEP_INTERVAL_HOURS: 3,
  START_BEFORE_HOURS: 9,
  EDITABLE_HOURS: 9,
  NAVIGATE_HOURS: 24,
  REPORT_INTERVAL_IN_MINUTES: 180,
});

export const TrafficLoadOccupancy = Object.freeze({
  STEP: [1, 2, 3, 4, 5],
  DURATION: [1, 2, 3, 4, 5, 6, 10, 15],
});

export const TrafficLoadEntry = Object.freeze({
  STEP: [1, 2, 3, 4, 5, 6, 10, 15, 20, 30, 60],
  DURATION: [1, 2, 3, 4, 5, 6, 10, 15, 20, 30, 60],
});

export const HotspotStatus = Object.freeze({
  NEW: 'NEW',
  DECLARED: 'DECLARED',
  ACTIVE: 'ACTIVE',
  STAM_ONGOING: 'STAM_ONGOING',
  STAM: 'STAM',
  DISMISSED: 'DISMISSED',
});

export const HotspotStatusColor = Object.freeze({
  DECLARED: '#005f8a',
  ACTIVE: '#008a6c',
  STAM_ONGOING: '#e2c900',
  STAM: '#008a5f',
  DISMISSED: '#939393',
});

export const HotspotStatusFontColor = Object.freeze({
  DECLARED: 'white',
  ACTIVE: 'white',
  STAM_ONGOING: 'black',
  STAM: 'white',
  DISMISSED: 'black',
});

export const HotspotsTypes = Object.freeze({
  NFA: 'NFA',
  RDT: 'RDT',
  DCB: 'DCB',
  DCB_ENTRY: 'DCB_ENTRY',
  COMPLEX: 'COMPLEX',
  CAT: 'CAT',
  WEATHER: 'Weather',
});

export const HotspotsLabels = Object.freeze({
  NFA: 'NFA',
  RDT: 'RDT',
  CAT: 'CAT',
  DCB: 'DCB',
  DCB_ENTRY: 'DCB_ENTRY',
  OCC: 'OCC',
  ENT: 'ENT',
  COMPLEX: 'COMP',
});

export const HotspotTypeHasChart = Object.freeze({
  RDT: false,
  CAT: false,
  DCB: true,
  DCB_ENTRY: true,
  COMPLEX: true,
});

export const selectedHotspotUpdateType = Object.freeze({
  NO_UPDATE: 'NO_UPDATE',
  UPDATED: 'UPDATED',
  REMOVED: 'REMOVED',
});

export const MattermostPaths = Object.freeze({
  CHAT: window.env.CHAT_URL,
  LOGIN: '/oauth/gitlab/login',
  API: '/api/v4',
  UNREAD: '/users/me/teams/unread',
  USER: '/users/me',
  USERS: '/users',
  TEAMS: '/teams',
  CHANNELS: '/channels',
  POSTS: '/posts',
  TEAM_NAME: 'atfm-dev-team',
  CHANNEL_NAME: 'implemented-measures',
});

export const SettingsLabels = Object.freeze({
  CAPA_PLAN_ENTRY: 'CAPACITY PLAN: ENTRY',
  CAPA_PLAN_OCC: 'CAPACITY PLAN: OCCUPANCY',
  AIRPORT_FLOWS: 'AIRPORT FLOWS',
});

export const HotspotsPaths = Object.freeze({
  DEFINED_AREAS: '/api/0/hotspots/user-defined-areas',
  WEATHERS: '/api/0/hotspots/weathers',
  DCBS: '/api/0/hotspots/dcb',
  DCBS_COMPUTE: '/api/0/hotspots/dcb/compute',
  DCBS_COMPLEXITY: true,
  PLAN: '/api/2/hotspots/plan',
});

export const TimelinePaths = Object.freeze({
  TIMELINE: '/api/2/timeline',
  SPLIT_HOTSPOTS: '/api/1',
  DCBS_COMPLEXITY: true,
});

export const WeathersPaths = Object.freeze({
  RDT: '/api/0/weathers/rdts',
  CAT: '/api/0/weathers/cats',
  SIGMET: '/api/v1/weather/noaa/sigmet',
});

export const DefinedAreaPaths = Object.freeze({
  DEF_AREAS: '/api/1/def-areas',
  NFA: '/nfa',
  UDA: '/uda',
});

export const XuningPaths = Object.freeze({
  CHARTS: '/api/1/charts',
  FLOW: '/api/0/flow',
  FORECAST: '/api/0/forecast/forecastSettings',
  FAVORITE_TFVS: '/api/0/favorite-tfvs',
  FEATURE: '/api/0/feature',
  CUSTOM_TAGS: '/api/0/tags',
  SETTINGS: '/api/0/user-settings',
  SECTO_CONF_LAYOUT: '/api/0/secto-conf-layout',
});

export const NMPaths = Object.freeze({
  NM_UPDATE: '/api/1/flow/update/nm',
});

export const TimePaths = Object.freeze({
  TIME: '/api/0/time',
});

export const GreenPaths = Object.freeze({
  IMPACT: '/api/0/impact',
});

export const StatusPaths = Object.freeze({
  AVD_STATUS: '/api/v1/status',
});

export const WhatifPaths = Object.freeze({
  MEASURE: '/measures',
  WHATIFS: '/api/1/whatifs',
  IMPLEMENT: '/implement',
  NEW_TRAJECTORY: '/preview/measures',
});

export const FlowPaths = Object.freeze({
  DECLARE: '/api/1/flow',
  PROPOSE: '/stam',
});

export const FlowSpotsPaths = Object.freeze({
  CLUSTERS: '/flowspots/api/clusters',
});

export const FlightsPaths = Object.freeze({
  FLIGHTS: '/api/1/flights',
  TRAJECTORIES: '/api/1/trajectories',
  PRIORITY: '/priority',
});

export const PositionsPaths = Object.freeze({
  POSITIONS: '/api/1/positions',
});

export const AirportsPaths = Object.freeze({
  AIRPORTS: '/',
});

export const UserInfoPaths = Object.freeze({
  USERINFO: '/api/v1/userinfo',
});

export const SectorsPaths = Object.freeze({
  SECTORS: '/api/1/secto-plans',
  POSSIBLE_CONFS: '/api/1/config',
  UPDATE_PLAN: '/api/1/plans',
  RELATIONSHIPS: '/api/0/secto-hierarchies/relationships',
});

export const CapaPlansPaths = Object.freeze({
  CAPA_PLANS: '/api/1/capa-plans',
  CAPA_PLAN_ENTRY: '/api/1/capa-plans/entry',
  CAPA_PLAN_OCC: '/api/1/capa-plans/occ',
});

export const OccupancyPaths = Object.freeze({
  OCCUPANCY: '/api/0/occ',
});

export const EntryPaths = Object.freeze({
  ENTRY: '/api/1/entry',
});

export const NotamPaths = Object.freeze({
  NOTAM: '/api/0/notam',
});

export const TfvPaths = Object.freeze({
  TFV: '/',
  REFLOCATION: '/reflocation',
});

export const FabCePaths = Object.freeze({
  FAB_CE: '/api/0/fab-ce',
});

export const FlightsConstants = Object.freeze({
  COMPLEXITY: true,
  DELTA_FROM: -900000,
  DELTA_PRETACT: -1800000,
});

export const AirspacesPaths = Object.freeze({
  AIRSPACE: '/',
});

export const ReportsPaths = Object.freeze({
  REPORTS: '/api/0/reports',
  REPORTS_HEADER: '/api/0/reports/headers',
  REPORTS_FIR: '/api/0/reports/fir',
  WIZARD: '/api/0/wizard',
});

export const KpisPaths = Object.freeze({
  KPIS: '/api/0/kpis',
});

export const TimerIntervals = Object.freeze({
  HOTSPOTS: 1 * Time.MINUTE_IN_MILISECONDS,
  RDT: 1 * Time.MINUTE_IN_MILISECONDS,
  CAT: 1 * Time.MINUTE_IN_MILISECONDS,
  NO_FLIGHT_AREAS: 1 * Time.MINUTE_IN_MILISECONDS,
  FLIGHTS: 1 * Time.MINUTE_IN_MILISECONDS,
  TRAJECTORIES: 1 * Time.MINUTE_IN_MILISECONDS,
  SECTORS: 1 * Time.MINUTE_IN_MILISECONDS,
  TIMELINE: 1 * Time.MINUTE_IN_MILISECONDS,
  WHAT_IF: 1 * Time.MINUTE_IN_MILISECONDS,
  TIME: 10000,
  MATTERMOST: 10000,
  NOTAM: 1 * Time.MINUTE_IN_MILISECONDS,
  STAMS: 1 * Time.MINUTE_IN_MILISECONDS,
  KPIS: 1 * Time.MINUTE_IN_MILISECONDS,
  SECTOR_EDIT_ERROR: 3000,
  DCB_HOTSPOTS_COMPUTE: 1 * Time.MINUTE_IN_MILISECONDS,
  TRAFFIC_LOADS: 1 * Time.MINUTE_IN_MILISECONDS,
  VERSION: 5 * Time.MINUTE_IN_MILISECONDS,
  CLOCK: 1 * Time.MINUTE_IN_MILISECONDS,
  FEATURE_FLAGS: 10 * Time.MINUTE_IN_MILISECONDS,
  DAY: 24,
  TMI: 1 * Time.MINUTE_IN_MILISECONDS,
});

export const StamsConstants = Object.freeze({
  STAMMED: true,
  TONB: 'TONB',
  LC: 'LC',
  LR: 'LR',
});

export const StamsStatus = Object.freeze({
  NEW: 'Draft',
  ACTIVE: 'Draft',
  STAM_ONGOING: 'Under assessment',
  STAM: 'Implemented',
  DISMISS: 'Rejected',
});

export const SectorRequestTimeWindow = Object.freeze({
  DELTA_FROM: 15 * Time.MINUTE_IN_MILISECONDS,
  DELTA_TO: 24 * Time.HOUR_IN_MILISECONDS,
});

export const TrafficLoadsTimeWindow = Object.freeze({
  BEFORE: 15 * Time.MINUTE_IN_MILISECONDS,
  AFTER: 3 * Time.HOUR_IN_MILISECONDS,
});

export const TimelineTimeWindow = Object.freeze({
  BEFORE: 15 * Time.MINUTE_IN_MILISECONDS,
});

export const TrafficLoadsTimeWindowConfig = Object.freeze({
  BEFORE: 20 * Time.MINUTE_IN_MILISECONDS,
  AFTER: 1 * Time.HOUR_IN_MILISECONDS,
});

export const OccupancyChartTimeWindow = Object.freeze({
  BEFORE: 15 * Time.MINUTE_IN_MILISECONDS,
  AFTER: 1 * Time.HOUR_IN_MILISECONDS,
});

export const MenuStatus = Object.freeze({
  MONITORING: 'monitoring',
  FLIGHTS: 'flights',
  CDM: 'cdm',
  APPROACH: 'approach',
  NOTAM: 'notam',
  WHATIF: 'whatif',
  ALL: 'all',
  NONE: 'none',
  SECTO: 'secto',
  TRAFFIC: 'traffic',
  STAMS: 'stams',
  PLANNING: 'planning',
  PRETACT: 'pretact',
  REPLAY: 'replay',
  TMI: 'tmi',
  HOTSPOTDETAILS: 'hotspot_details',
});

export const OperationType = Object.freeze({
  PRE_TACTICAL: 'pre-tactical',
});

export const ModalStatus = Object.freeze({
  SETTINGS: 'Settings',
  GENERAL: 'General',
});

export const DefinedAreasTypes = Object.freeze({
  D: 'D',
  R: 'R',
  TRA: 'TRA',
  TSA: 'TSA',
  UDA: 'UDA',
});

export const DefinedAreasDisplayStatus = Object.freeze({
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  NONE: 'NONE',
});

export const UserDefinedAreasTypes = Object.freeze({
  POLYGON: 'Polygon',
  CIRCLE: 'Circle',
});

export const TooltipTypes = Object.freeze({
  UDA: 'USER DEFINED AREA',
  NFA: 'NO FLY AREA',
});

export const RestrictedFlyAreasStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
});

export const FilterTypes = Object.freeze({
  BRUSH: 'brush',
  LIST: 'list',
  HOTSPOT: 'hotspot',
});

export const WhatifMeasures = Object.freeze({
  TONB: 'TONB',
  LC: 'LC',
  LR: 'LR',
  RRP: 'RRP',
  GDP: 'GDP',
});

export const WhatifMeasuresValidationStatus = Object.freeze({
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  STAM_ERROR: 'STAM_ERROR',
  OK: 'OK',
});

export const CommonRegex = Object.freeze({
  NOTAM_REGEX: '^[0-9]{4}[a-zA-Z][0-9]{5}[a-zA-Z][0-9]{3}$',
});

export const ErrorMessages = Object.freeze({
  DECLARATION_HOTSPOT_ERROR: 'Error declaring the hotspot',
  DELETING_WHATIF_ERROR: 'Error deleting the whatif',
});

export const SettingsValidationMessages = Object.freeze({
  DURATION_STEP: 'Step should be inferior or equal to duration',
});

export const CommonValidationMessages = Object.freeze({
  FL_NUMBER: 'FLight levels must be integers',
  FL_POSITIVE: 'Flight levels must be positive',
  FL_MAX_NUMBERS: 'Flight levels must have no more than 4 numbers',
  FL_MAX_LOWER_THAN_MIN: "FL Max can't be lower than FL Min",
  DATE_INVALID_FORMAT: 'Date must be in DD/MM/YYYY format',
  TIME_INVALID_FORMAT: 'Time must be in HH:mm format',
  FIELD_MANDATORY: 'Field is mandatory',
  TO_SMALLER_FROM_ERROR: 'TO value must not be lower or equal than the FROM',
});

export const GdpValidationMessages = Object.freeze({
  RATE_INVALID: 'Rate must be an integer greater or equal than 0',
  TIME_DIFF_ERROR_TOO_SHORT:
    'Time difference between FROM and TO must be more than 20 minutes',
  TIME_DIFF_ERROR_TOO_LONG:
    'Time difference between FROM and TO must be less than 6 hours',
});

export const NoFlyAreaEditValidationMessages = Object.freeze({
  NAME_IS_EMPTY: "Name can't be empty",
  NAME_TOO_LONG: "Name can't be longer than 10 chars",
  NOTAM_IS_EMPTY: "Area can't be empty",
  NOTAM_LENGTH: 'Paste last 14 char from NOTAM Q)',
  NOTAM_REG_EX: "Area can't be invalid NOTAM Q)",
  NOTAM_RADIUS_TOO_BIG: "Area radius can't be bigger than 200NM",
  NOTAM_LATITUDE_COORDINATES: 'Area contains invalid latitude coordinates',
  NOTAM_LONGITUDE_COORDINATES: 'Area contains invalid longitude coordinates',
  START_AND_END_TIME_SHOULD_NOT_BE_THE_SAME:
    'Start and End time should not be the same',
  END_TIME_LOWER_THAN_START: "End time can't be before start",
  END_TIME_NOT_IN_FUTURE: 'End time must be in the future',
  PAST_START_TIME_NO_EDIT: "Start time is in the past, it can't be edited",
});

export const SectorPlanEditErrorMessages = Object.freeze({
  SAVING_CHANGES_ERROR: 'Error saving sector plan',
  UNABLE_TO_INSERT_NEW_ENTRY: "Can't insert conf here",
});

export const SectorValidationMessages = Object.freeze({
  FROM_BIGGER_TO_ERROR: 'From value must not be higher or equal than the to',
  FROM_VALUE_ERROR:
    'From value must not be lower or equal than previous entry from',
  FROM_VALUE_START_ERROR: 'From value of first entry must be 0000',
  TO_SMALLER_FROM_ERROR: 'To value must not be lower or equal than the from',
  TO_VALUE_ERROR:
    'To value must not be higher or equal than following entry to',
  TO_VALUE_END_ERROR: 'To value of last entry must be 0000',
});

export const TrafficLoadListMessages = Object.freeze({
  FROM_BIGGER_TO_ERROR: 'From value must not be higher or equal than the to',
  TO_SMALLER_FROM_ERROR: 'To value must not be lower or equal than the from',
});

export const StamMessages = Object.freeze({
  NOTE_TITLE: '{type} STAM measure proposal',
  TONB_EOBT:
    'Proposing {type} measure on {callsign} with new EOBT at {newEobt}',
  LC_SECTOR:
    'Proposing {type} measure on {callsign} with new sector at {newSector}',
  LC_MAX:
    'Proposing {type} measure on {callsign} with new FL MAX at {newMaxFL}',
  LC_MIN:
    'Proposing {type} measure on {callsign} with new FL MIN at {newMinFL}',
  LC_MAX_MIN:
    'Proposing {type} measure on {callsign} with new FL MAX at {newMaxFL} and with new FL MIN at {newMinFL}',
  LR_CALLSIGN: 'Proposing {type} measure on {callsign}',
});

export const GdpMessages = Object.freeze({
  NOTE_TITLE: '{type} measure proposal',
  GDP: 'Proposing {type} measure on sector {newSector}, from {newFrom} to {newTo}, with new rate at {newRate}',
});

export const SnackBarMessages = Object.freeze({
  DEIMPLEMENT_MESSAGE_SUCCESS:
    'Your measures have been de-implemented successfully',
  DEIMPLEMENT_MESSAGE_ERROR:
    'Something went wrong while de-implementing your measures',
  IMPLEMENT_MESSAGE_SUCCESS: 'Your measures have been implemented successfully',
  IMPLEMENT_MESSAGE_ERROR:
    'Something went wrong while implementing your measures',
  SHARE_MESSAGE_SUCCESS:
    'Your message with measures has been sent successfully',
  SHARE_MESSAGE_ERROR:
    'Something went wrong while sending your message with measures',
  SERVICE_API_FAILURE: 'Service {service} is failing',
  REPLAY_SERVICE_NOT_SUPPORTED: 'Replay of {service} is not supported',
  FLIGHT_SELECTION_ERROR: 'Please select a flight to create a STAM',
  DELETING_TMI_SUCCESS: 'Draft TMI deleted with success',
  DELETING_TMI_ERROR: 'Error deleting the draft TMI',
  NO_NETWORK_CONNECTION: 'No connection to network',
  SECTO_PLAN_UPDATE: 'Secto Plan updated',
  NM_UPDATE_SUCCESS: 'Plan successfully retrieved from NM',
  NM_UPDATE_ERROR: 'Something went wrong',
});

export const ReroutingMessages = Object.freeze({
  REROUTING_MESSAGE_ERROR:
    'Error occurred during trajectory preview computation',
});

export const TMIMessages = Object.freeze({
  NEW_TMI_MESSAGE: 'A new CDM TMI was created.',
});

export const WeatherTypes = Object.freeze({
  STORM: 'TS',
  TURBULENCE: 'TURB',
});

export const FabCeTimes = Object.freeze({
  INTERVAL_NUMBER: 4,
  FORECAST_INTERVAL: 2 * Time.HOUR_IN_MILISECONDS,
});

export const ForecastSources = Object.freeze({
  TIMELINE: 'TIMELINE',
  HOTSPOT: 'HOTSPOT',
  FABCE: 'FABCE',
});

export const SectoLayerColors = Object.freeze({
  HOTSPOT_SECTOR: '#FF3455',
  FABCE_NOSIG: '#00ea8e',
  FABCE_ISOL: '#f8e71c',
  FABCE_OCNL: '#f8961c',
  FABCE_FRQ: '#ff3455',
});

export const PlanReportSectionType = Object.freeze({
  SECTO_PLAN: 'SECTO_PLAN',
});

export const PlanReportElementType = Object.freeze({
  ACTIVE_CONF: 'ACTIVE_CONF',
  OCC_LOAD: 'OCC_LOAD',
  ENTRY_LOAD: 'ENTRY_LOAD',
});

export const PlanReportStatus = Object.freeze({
  NEW: 'NEW',
  UPDATED: 'UPDATED',
  ARCHIVED: 'ARCHIVED',
});

export const RegularExpressions = Object.freeze({
  WAYPOINTS: /^[0-9]+[N,S][0-9]+[E,W]$/,
  WAYPOINTS_CAL: '-CAL-',
  WAYPOINTS_TOC: '-TOC-',
  WAYPOINTS_TOD: '-TOD-',
  WAYPOINTS_APR: '-APR-',
  STRING_LAST_DIGITS: /\d+$/,
});

export const PossiblePointMarkers = Object.freeze({
  WAYPOINT: 'WAYPOINT',
  APR: 'APR',
  LR: 'LR',
});

export const defaultFlowStyle = Object.freeze({
  AIRPORT: {
    color: '#2566e2',
    borderColor: '#2566e2',
    opacity: 1,
    fillOpacity: 1,
    weight: 3,
    border: 1.5,
  },
  TRAJECTORY: {
    color: '#b4ccf9',
    borderColor: '#b4ccf9',
    opacity: 1,
    fillOpacity: 1,
    weight: 3,
    border: 1.5,
  },
  ROUTE: {
    iconColor: '#ffffff',
    opacity: 1,
    fillOpacity: 1,
    weight: 3,
    border: 0,
  },
});

export const RouteTrajectoryStyles = Object.freeze({
  DEFAULT: { ...defaultFlowStyle.ROUTE },
  HIGH: {
    ...defaultFlowStyle.ROUTE,
    color: '#ce64cd',
    borderColor: '#f0fbf7',
    weight: 5,
  },
  MEDIUM: {
    ...defaultFlowStyle.ROUTE,
    color: '#8e5acb',
    borderColor: '#f0fbf7',
  },
  LOW: {
    ...defaultFlowStyle.ROUTE,
    color: '#4a1ea6',
    borderColor: '#f0fbf7',
    weight: 2,
  },
});

export const FlowStyles = Object.freeze({
  DEFAULT: { ...defaultFlowStyle.AIRPORT },
  TRAJECTORY: { ...defaultFlowStyle.TRAJECTORY },
  GREEN: {
    ...defaultFlowStyle.AIRPORT,
    color: '#00e676',
    borderColor: '#176B4C',
  },
  BLUE: {
    ...defaultFlowStyle.AIRPORT,
    color: '#2196f3',
    borderColor: '#224E77',
  },
  PURPLE: {
    ...defaultFlowStyle.AIRPORT,
    color: '#5e35b1',
    borderColor: '#382D60',
  },
  INDIGO: {
    ...defaultFlowStyle.AIRPORT,
    color: '#26c6da',
    borderColor: '#245E6E',
  },
  LIME: {
    ...defaultFlowStyle.AIRPORT,
    color: '#cddc39',
    borderColor: '#5E6637',
  },
  ORANGE: {
    ...defaultFlowStyle.AIRPORT,
    color: '#ff7043',
    borderColor: '#6F413A',
  },
  PINK: {
    ...defaultFlowStyle.AIRPORT,
    color: '#f6a5c0',
    borderColor: '#6D5365',
  },
  MAUVE: {
    ...defaultFlowStyle.AIRPORT,
    color: '#9c27b0',
    borderColor: '#4D295F',
  },
  RED: {
    ...defaultFlowStyle.AIRPORT,
    color: '#f44336',
    borderColor: '#6C3136',
  },
  YELLOW: {
    ...defaultFlowStyle.AIRPORT,
    color: '#fff176',
    borderColor: '#716F4C',
  },
});

export const FlowMarkerStyles = Object.freeze({
  DEFAULT: {
    ...defaultFlowStyle.AIRPORT,
    color: '#ffffff',
    borderColor: '#304ffe',
  },
  GREEN: {
    ...defaultFlowStyle.AIRPORT,
    color: '#00e676',
    borderColor: '#176B4C',
  },
  BLUE: {
    ...defaultFlowStyle.AIRPORT,
    color: '#2196f3',
    borderColor: '#224E77',
  },
  PURPLE: {
    ...defaultFlowStyle.AIRPORT,
    color: '#5e35b1',
    borderColor: '#382D60',
  },
  INDIGO: {
    ...defaultFlowStyle.AIRPORT,
    color: '#26c6da',
    borderColor: '#245E6E',
  },
  LIME: {
    ...defaultFlowStyle.AIRPORT,
    color: '#cddc39',
    borderColor: '#5E6637',
  },
  ORANGE: {
    ...defaultFlowStyle.AIRPORT,
    color: '#ff7043',
    borderColor: '#6F413A',
  },
  PINK: {
    ...defaultFlowStyle.AIRPORT,
    color: '#f6a5c0',
    borderColor: '#6D5365',
  },
  MAUVE: {
    ...defaultFlowStyle.AIRPORT,
    color: '#9c27b0',
    borderColor: '#4D295F',
  },
  RED: {
    ...defaultFlowStyle.AIRPORT,
    color: '#f44336',
    borderColor: '#6C3136',
  },
  YELLOW: {
    ...defaultFlowStyle.AIRPORT,
    color: '#fff176',
    borderColor: '#716F4C',
  },
});

export const FlowScope = Object.freeze({
  ARRIVING: 'ARRIVING',
  DEPARTING: 'DEPARTING',
  GLOBAL: 'GLOBAL',
});

export const unknownAirports = Object.freeze({
  ZZZZ: 'ZZZZ',
});

export const trajectoryPlanType = Object.freeze({
  DEP: 'DEP',
  DES: 'DES',
  FULL: 'FULL',
});

export const LateralReroutingModeStatus = Object.freeze({
  NEW: 'NEW',
  DRAWING: 'DRAWING',
  FINISHED: 'FINISHED',
});

export const UnamedSettings = Object.freeze({
  DURATION: [
    '01h',
    '02h',
    '03h',
    '04h',
    '05h',
    '06h',
    '07h',
    '08h',
    '09h',
    '10h',
    '11h',
    '12h',
    '13h',
    '14h',
    '15h',
    '16h',
    '17h',
    '18h',
    '19h',
    '20h',
    '21h',
    '22h',
    '23h',
    '24h',
  ],
});

export const FeatureTypes = Object.freeze({
  WEATHER: 'WEATHER',
  SHOW_COMP_HOTSPOTS: 'SHOW_COMPLEXITY_HS',
});

export const AircraftTypes = Object.freeze({
  PISTON: 'PISTON',
  TURBOPROP: 'TURBOPROP',
  MEDIUM_JET: 'MEDIUM_JET',
  SMALL_JET: 'SMALL_JET',
  HEAVY_JET: 'HEAVY_JET',
  JUMBO_JET: 'JUMBO_JET',
});

export const PathNames = Object.freeze({
  POSTOPS: '/postops',
  PRETACT: '/pretact',
});

export const StorageKeys = Object.freeze({
  PARSED_TOKEN: 'ad_parsed_token',
  BEARER_TOKEN: 'ad_access_token',
  POPUP_TFVS: 'popup_tfvs',
  POPUP_TFVS_POSITIONS: 'popup_tfvs_positions',
});

export const Horizon = Object.freeze({
  PRETACTICAL: 'PRETACTICAL',
  TACTICAL: 'TACTICAL',
  STRATEGIC: 'STRATEGIC',
});
