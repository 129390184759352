export const selectUser = state => state.user;

export const selectUserId = state => selectUser(state).uid;

export const selectUserArea = state => selectUser(state).area;

export const selectUserIsUpdatingEntry = state => selectUser(state).isUpdatingEntry;

export const selectUserIsUpdatingOcc = state => selectUser(state).isUpdatingOcc;

export const selectUserTfv = state => (selectUser(state) ? selectUser(state).tfv : undefined);

export const selectUserAcc = state => (selectUser(state) ? selectUser(state).acc : undefined);

export const selectUserSecto = state => (selectUser(state) ? selectUser(state).secto : undefined);

export const selectUserForecastPeriod = state => selectUser(state).forecastPeriod;

export const selectUserForecastPeriodCharts = state => selectUser(state).forecastPeriodCharts;
