import callApi from '../api/services';
import { OccupancyPaths, FlightsConstants } from '../../constants';

const queryOccupancyData = (tfvs, start, end, step, duration, sessionId, waitForJob, horizon) => {
  let params = '';
  const tfvsQueryParameter = tfvs.reduce((acc, tfv, index) => (index === 0 ? `${acc}${tfv}` : `${acc},${tfv}`), '');

  if (step) {
    params = `${params}&step=${step}`;
  }
  if (duration) {
    params = `${params}&duration=${duration}`;
  }
  if (sessionId) {
    params = `${params}&sessionId=${sessionId}`;
  }
  if (waitForJob) {
    params = `${params}&waitForJob=${waitForJob}`;
  }
  if (horizon) {
    params = `${params}&horizon=${horizon}`;
  }

  return callApi(
    `${OccupancyPaths.OCCUPANCY}?tfv=${tfvsQueryParameter}&start=${start}&end=${end}&complexity=${FlightsConstants.COMPLEXITY}${params}`,
    { basePath: window.env.OCCUPANCY_API_BASE_URL },
  );
};

export default queryOccupancyData;
