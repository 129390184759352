import queryAirspacesData from './services';

export const LOADING_AIRSPACES_DATA = 'LOADING_AIRSPACES_DATA';
export function loadingAirspacesData() {
  return {
    type: LOADING_AIRSPACES_DATA,
  };
}

export const LOADING_AIRSPACES_DATA_SUCCESS = 'LOADING_AIRSPACES_DATA_SUCCESS';
export function loadingAirspacesDataSuccess(neighboursData) {
  return {
    type: LOADING_AIRSPACES_DATA_SUCCESS,
    neighboursData,
  };
}

export const LOADING_AIRSPACES_DATA_FAILURE = 'LOADING_AIRSPACES_DATA_FAILURE';
export function loadingAirspacesDataFailure(error) {
  return {
    type: LOADING_AIRSPACES_DATA_FAILURE,
    error,
  };
}

/* Async Action Creators */
export const fetchAirspacesData = neighboursList => (dispatch) => {
  dispatch(loadingAirspacesData());
  const allPromises = neighboursList.map(neighbour => queryAirspacesData(neighbour));
  return Promise.all(allPromises).then((data) => {
    dispatch(loadingAirspacesDataSuccess(data));
  }).catch((error) => {
    dispatch(loadingAirspacesDataFailure(error));
  });
};
