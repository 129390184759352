import {
  LOADING_AIRSPACES_DATA,
  LOADING_AIRSPACES_DATA_SUCCESS,
  LOADING_AIRSPACES_DATA_FAILURE,
} from './actions';

const initial = {
  airspaces: [],
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case LOADING_AIRSPACES_DATA: {
      return state;
    }
    case LOADING_AIRSPACES_DATA_SUCCESS: {
      return { ...state, airspaces: action.neighboursData };
    }
    case LOADING_AIRSPACES_DATA_FAILURE: {
      return { ...state, error: action.error };
    }
    default: {
      return state;
    }
  }
};
